import i18next from 'i18next';

let defaultLang;
const isBrowser = typeof window !== 'undefined';
if (isBrowser) {
	defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.includes('zh') ? 'tc' : navigator.language.includes('ja') ? 'jp' : 'en';
}
i18next.init({
  lng: defaultLang,
  fallbackLng: 'common',
  // detection: {
  //   // I remove all detectors to make sure it will use the i18nOptions.fallbackLng
  //   order: [],
  // },
	resources: {
    common: {
			translations: require('../locales/common.json')
		},
		en: {
			translations: require('../locales/en/translations.json')
		},
		tc: {
			translations: require('../locales/tc/translations.json')
		},
		jp: {
			translations: require('../locales/jp/translations.json')
		},
	},
	ns: ['translations'],
	defaultNS: 'translations',
	returnObjects: true,
	debug: process.env.NODE_ENV === 'development',
	react: {
		wait: true,
	},
});

i18next.languages = ['en', 'tc', 'jp'];

export default i18next;