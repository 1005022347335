/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles';
import { withTrans } from '../i18n/withTrans'
// import LanguageBox from './languageBox'
import Header from "./header"
// import "./layout.css"
import smoothscroll from 'smoothscroll-polyfill';

const isBrowser = typeof window !== 'undefined';
if (isBrowser) {
  smoothscroll.polyfill();
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [display, setDisplay] = useState('hide')
  const hideHandleClick = (lang) => {
    localStorage.setItem('display', 'hide');
    setDisplay(true);
  }
  useEffect(() => {
    setDisplay(localStorage.getItem('display') === 'hide' ? true : false)
  }, [])

  const proza = {
    fontFamily: 'Nunito Sans, Arial',
    fontStyle: 'normal',
    fontWeight: 400,
  };

  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Nunito Sans, Arial',
      fontSize: 16,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [proza],
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div id="canvas-container-front" style={{ position: "fixed", zIndex: -1, visibility: "hidden"}}>
      </div>
      <div id="canvas-container-back" style={{ position: "fixed", zIndex: -1, visibility: "hidden"}}>
      </div>
      <div className="layout">
        {children}
        <footer>
        </footer>
      </div>
      {/* <LanguageBox display={display} hideHandleClick = {hideHandleClick} /> */}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
