import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `UPDATE_COLOR`) {
    return Object.assign({}, state, {
      currentDesign: action.designValue
    })
  } else if (action.type === `UPDATE_LANG`) {
    localStorage.setItem('lang', action.langValue);
    return Object.assign({}, state, {
      currentLang: action.langValue
    })
  } else if (action.type === `UPDATE_INFO`) {
    return Object.assign({}, state, {
      currentInfo: action.infoValue
    })
  } else if (action.type === `UPDATE_REGION`) {
    // localStorage.removeItem('myDesign');
    localStorage.setItem('region', action.regionValue);
    return Object.assign({}, state, {
      currentRegion: action.regionValue
    })
  } else if (action.type === `UPDATE_STAGE`) {
    return Object.assign({}, state, {
      currentStage: action.stageValue
    })
  } else if (action.type === `UPDATE_STOCK`) {
    return Object.assign({}, state, {
      currentStock: action.stockValue
    })
  }
  return state
}

const isBrowser = typeof window !== 'undefined';
let subdomain
if (isBrowser) {
  subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0];
}

const initialState = {
  currentDesign: {
    product: "blank",
    case: [
      { comp: "front upper panel", color: "black" },
      { comp: "front middle panel", color: "black" },
      { comp: "front lower panel", color: "black" },
      { comp: "back panel", color: "black" },
      { comp: "nylon tape", color: "black_t" },
      { comp: "zipper", color: "black_z" },
      { comp: "zipper puller", color: "black_p" },
      { comp: "label", color: "classic" }
    ],
    tag: ""
  },
  currentInfo: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    region: '',
    store: '',
    date: '',
    time: '',
  },
  currentLang: 'en',
  currentRegion: subdomain && !subdomain.includes('localhost') ? subdomain : 'tw',
  currentStage: 'design-pick',
  currentStock: false
}

// let persistedState
// if (isBrowser) {
//   persistedState = localStorage.getItem('myDesign') ? { currentDesign: JSON.parse(localStorage.getItem('myDesign'))} : initialState
// } else {
//   persistedState = initialState
// }

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore