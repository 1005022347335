import React, { useState, useEffect } from "react"
import { Select, MenuItem, Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { updateLang, updateRegion } from '../actions'
import { defaultStaticContext } from '../context/StaticContext'

const mapStateToProps = ({ currentLang, currentRegion }) => {
  return { currentLang, currentRegion }
}

const mapDispatchToProps = dispatch => ({
  updateLang: value => dispatch(updateLang(value)),
  updateRegion: regionValue => dispatch(updateRegion(regionValue))
})

const useStyles = makeStyles({
  selectFocus: {
    "&:focus": {
      background: 'transparent'
    }
  }
})

const restart = (currentRegion) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.location.href = "https://" + currentRegion + ".sth-like.com";
    localStorage.removeItem('myDesign');
  }
  
}

const LanguageMenu = ({ updateLang, updateRegion, currentLang, currentRegion }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const regionList = {
    'hk': t('regionList')[0],
    'jp': t('regionList')[1],
    'tw': t('regionList')[2]
  }

  const isBrowser = typeof window !== 'undefined';
  let defaultLang
  if (isBrowser) {
    defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : currentLang;
  }

  const [values, setValues] = useState({
    language: defaultLang,
    region: currentRegion
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeLang = value => {
    i18n.changeLanguage(value)

    setValues(oldValues => ({
      ...oldValues,
      language: value,
    }));
    updateLang(value)
  }

  const handleChangeRegion = value => {
    setValues(oldValues => ({
      ...oldValues,
      region: value,
    }));
    // updateRegion(value)
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    handleChangeLang(defaultLang)
  }, [currentLang]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChangePanel = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const MenuExpansionPanel = withStyles({
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: '0',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0',
      },
    },
    expanded: {},
  })(Accordion);

  const MenuExpansionPanelSummary = withStyles({
    root: {
      minHeight: `auto`,
      '&$expanded': {
        minHeight: `auto`,
      },
    },
    content: {
      margin: '0',
      display: `flex`,
      justifyContent: `space-between`,
      '&$expanded': {
        margin: 0,
        marginBottom: '5px',
      },
    },
    expanded: {},
  })(AccordionSummary);

  const MenuExpansionPanelDetails = withStyles({
    root: {
      padding: 0,
      display: `block`,
      // textAlign: `center`
    }
  })(AccordionDetails); 

  return(
    <>
    {/* <Select
      value={values.language}
      onChange={(e) => handleChangeLang(e.target.value)}
      disableUnderline
      inputProps={{
        name: 'language'
      }}
      classes={{
        select: classes.selectFocus
      }}
    >
      <MenuItem value={'en'}>EN</MenuItem>
      <MenuItem value={'tc'}>TC</MenuItem>
      <MenuItem value={'jp'}>jp</MenuItem>
    </Select> */}
      <div className="langWrapper">
        <div className="langWrapper__label">{ t('language') }: </div>
        <MenuExpansionPanel className="langWrapper__dropdown" expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
          <MenuExpansionPanelSummary
            aria-controls="panel1bh-content"
            id="lang-header"
          >
            <span>{defaultStaticContext.langList[values.language]}</span><ArrowDropDownIcon />
          </MenuExpansionPanelSummary>
          <MenuExpansionPanelDetails>
          
            {Object.keys(defaultStaticContext.langList).map((key, index) => (
              <div
                className="langWrapper__item"
                style={{ display: values.language === key ? 'none': 'block'}}
                key={key}
                value={key}
                onClick={() => {handleChangeLang(key); setExpanded(false) }}>
                {defaultStaticContext.langList[key]}
              </div>
            ))}
          </MenuExpansionPanelDetails>
        </MenuExpansionPanel>
      </div>
      <div className="langWrapper">
        <div className="langWrapper__label">{ t('region') }: </div>
        <MenuExpansionPanel expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
          <MenuExpansionPanelSummary
            aria-controls="panel2bh-content"
            id="region-header"
          >
            <span>{regionList[values.region]}</span><ArrowDropDownIcon />
          </MenuExpansionPanelSummary>
          <MenuExpansionPanelDetails>
            {Object.keys(regionList).map((key) => (
              <div
                className="langWrapper__item"
                key={key}
                value={key}
                style={{ display: values.region === key ? 'none': 'block'}}
                onClick={() => {handleChangeRegion(key); setExpanded(false); handleClickOpenDialog() }}>
                {regionList[key]}
              </div>
            ))}
          </MenuExpansionPanelDetails>
        </MenuExpansionPanel>
      </div>
      
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="langDialog"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('select-region')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            { t('header.notify')[0] + regionList[values.region] + t('header.notify')[1] }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="basicButton" onClick={() => {handleCloseDialog(); handleChangeRegion(currentRegion)}}>
            { t('back') }
          </Button>
          <Button className="basicButton" onClick={() => {handleCloseDialog(); updateRegion(values.region); restart(values.region) }}>
            { t('proceed') }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageMenu)
