import React from "react"
import clsx from 'clsx';
import { Link, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import LanguageMenu from './languageMenu'
import ShareBox from './shareBox'
import { AppBar, Toolbar, IconButton, Typography, Drawer, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import logo from '../images/logo.png'
import { connect } from "react-redux"
import { updateLang, updateRegion } from '../actions';
import guide0 from '../images/step0.jpg'
import guide1 from '../images/step1.jpg'
import guide2 from '../images/step2.jpg'
import guide3 from '../images/step3.jpg'

const mapStateToProps = ({ currentLang, currentRegion }) => {
  return { currentLang, currentRegion }
}

const mapDispatchToProps = dispatch => ({
  updateLang: langValue => dispatch(updateLang(langValue)),
  updateRegion: regionValue => dispatch(updateRegion(regionValue))
})

const drawerHeight = 240;

const useStyles = makeStyles(theme => ({
  fullList: {
    width: 'auto',
    marginTop: '48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1400,
    color: `#212121`,
    backgroundColor: `#fff`,
    boxShadow: `none`,
    borderBottom: `1px solid #999`
  },
  appBarShift: {
    color: `#212121`,
    backgroundColor: `#fff`,
    boxShadow: `none`,
    borderBottom: `none`
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  drawer: {
    height: `100%`,
  },
  drawerPaper: {
    height: `100%`,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const Header = ({ currentLang, currentRegion, updateLang, updateRegion }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [openMobile, setOpenMobile] = React.useState(false);
  const [openDesktop, setOpenDesktop] = React.useState(false);
  const [openHow, setOpenHow] = React.useState(false);
  let defaultLang, defaultRegion

  const handleClickOpenHow = () => {
    setOpenHow(true);
  };

  const handleCloseHow = () => {
    setOpenHow(false);
  };

  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : currentLang;
    // defaultRegion = localStorage.getItem('region') ? localStorage.getItem('region') : currentRegion;
    // updateRegion(defaultRegion)
    updateLang(defaultLang)
  }

  // const toggleDrawerMobile = (open) => event => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }
  //   setOpenMobile(open);
  // };

  const toggleDrawerDesktop = (open, how) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDesktop(open);
    if (how === 'how') {
      handleClickOpenHow()
    }
  };

  const imageArray = [guide0, guide1, guide2, guide3]

  const { t } = useTranslation()

  return (
    <div className={clsx(classes.root, 'header')}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openMobile,
        })}
      >
        <Toolbar className="toolbar">
          <div className={classes.title}>
            <a
              href="/"
              style={{
                textDecoration: `none`,
                display: `block`,
              }}
            >
              <img
                src={logo}
                className="logo"
              />
            </a>
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDesktop?toggleDrawerDesktop(false):toggleDrawerDesktop(true)}
            edge="end"
            className={clsx(classes.menuButton, openDesktop, 'mobile')}
          >
            { openDesktop? <CloseIcon /> : <MenuIcon /> }
          </IconButton>
          <div className="menu__wrapper desktop">
            {/* <Link to="/about">{ t('header.about') }</Link> */}
            <button onClick={toggleDrawerDesktop(false, 'how')}>{ t('header.how') }</button>
            <Link to="/product">{ t('header.customiseFull') }</Link>
            <button onClick={openDesktop?toggleDrawerDesktop(false):toggleDrawerDesktop(true)}>{ t('header.setting') }&nbsp;<ArrowDropDownIcon /></button>
            {/* <button onClick={() => restart()}>Restart</button> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer className={classes.drawer} anchor="right" open={openDesktop} onClose={toggleDrawerDesktop(false)}>
        <div
          className={clsx(classes.fullList, 'drawer__wrapper')}
          role="presentation"
          // 
          onKeyDown={toggleDrawerDesktop(false)}
        >
          <div className="drawer__links">
            <Link className="mobile" onClick={toggleDrawerDesktop(false)} to="/about">{ t('header.about') }</Link>
            <Link className="mobile" onClick={toggleDrawerDesktop(false)} to="/product">{ t('header.customise') }</Link>
            <LanguageMenu />
            <Link onClick={toggleDrawerDesktop(false)} to="/store-list">{ t('header.storelist') }</Link>
          </div>
          <div>
            <ShareBox />
            {/* <span className="condition__link" onClick={() => handleOpenCondition()}>Terms, Conditions & Privacy Policy</span> */}
            <div className="condition__link">
              <a href="/condition">{ t('checkout.condition') }</a>
              <a href="/privacy">{ t('checkout.privacy') }</a>
            </div>
            <div className="condition__link">
              <a href="mailto:Enquiry_asia@gregorypacks.com">{ t('header.contactUs') }</a>
            </div>
          </div>
          {/* <button onClick={() => restart()}>Restart</button> */}
        </div>
      </Drawer>
      <Dialog
        open={openHow}
        keepMounted
        onClose={handleCloseHow}
        aria-labelledby="alert-dialog-slide-title-how"
        aria-describedby="alert-dialog-slide-description-how"
        className="langDialog"
      >
        <DialogTitle id="alert-dialog-slide-title-how">{ t('header.how') + ' ?' }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description-how">
            
          </DialogContentText>
          <ul className="howBox">
            { imageArray.map((item, i) => (
              <li className="howBox__item" key={i}>
                <img src={item} />
                <span className="howBox__text">{ t('header.guide')[i] }</span>
                {/* <span className="howBox__number">{ i + 1 }</span> */}
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button className="basicButton" onClick={() => handleCloseHow()}>
            { t('header.got') }
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Drawer className={clsx(classes.drawer, 'mobile')} anchor="top" open={openMobile} onClose={toggleDrawerMobile(false)}>
        <div
          className={clsx(classes.fullList, 'drawer__wrapper')}
          role="presentation"
          // 
          onKeyDown={toggleDrawerMobile(false)}
        >
          <LanguageMenu />
          <ShareBox />
        </div>
      </Drawer> */}
    </div>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
